import React, { useState, useEffect, useReducer, useContext, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';
import { socketConnection } from "../../services/socket";

import KiteCard from "./KiteCard"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  button: {
    background: "#10a110",
    border: "none",
    padding: "4px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "5px",
    "font-size": "inherit",
  },

}));

let jsonString;
const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();

  const [tags, setTags] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);


  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];

      setTags(fetchedTags);

      // Fetch tickets after fetching tags
      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const [file, setFile] = useState({
    lanes: []
  });


  const [tickets, setTickets] = useState([]);
  const { user } = useContext(AuthContext);
  const { profile, queues } = user;
  jsonString = user.queues.map(queue => queue.UserQueue.queueId);

  //region kanbam inserido pelo openAi
  const socket = socketConnection({ companyId: user.companyId });
  
  socket.on(`company-${user.companyId}-kanbam`, async (data) => {
    fetchTags();
  }
);



  const fetchTickets = async (jsonString) => {
    try {

      const { data } = await api.get("/ticket/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString),
          teste: true
        }
      });
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };


  const popularCards = (jsonString) => {
    const filteredTickets = tickets.filter(ticket => 
      ticket.tags.length === 0
      && ticket.status != "closed" 
    );

    const lanes = [
      {
        id: "lane0",
        title: i18n.t("Inbox"),
        //label: "0",
        cards: filteredTickets.map(ticket => ({
          id: ticket.id.toString(),
          label: "Ticket nº " + ticket.id.toString(),
          description: (
            <div>
              <p>
                {/*
                  {ticket.contact.number}
                  <br />
                  */}
                {ticket.lastMessage.substring(0, 75)}
                {ticket.lastMessage.length > 75 ?  '...' : null }
              </p>
              <button
                className={classes.button}
                onClick={() => {
                  handleCardClick(ticket.uuid)
                }}>
                Ver Ticket
              </button>
            </div>
          ),
          title: ticket.contact.name,
          draggable: true,
          href: "/tickets/" + ticket.uuid,
          avatarUrl: ticket.contact.profilePicUrl,
        })),
      },
      ...tags.map(tag => {
        const filteredTickets = tickets.filter(ticket => {
          const tagIds = ticket.tags.map(tag => tag.id);
          return tagIds.includes(tag.id);
        });

        return {
          id: tag.id.toString(),
          title: tag.name,
          //label: tag.id.toString(),
          cards: filteredTickets.map(ticket => ({
            id: ticket.id.toString(),
            label: "Ticket nº " + ticket.id.toString(),
            description: (
              <div>
                <p>
                  {/*
                  {ticket.contact.number}
                  <br />
                  */}
                  {ticket.lastMessage.substring(0, 75)}
                  {ticket.lastMessage.length > 75 ? '...' : null }
                </p>
                <button
                  className={classes.button}
                  onClick={() => {

                    handleCardClick(ticket.uuid)
                  }}>
                  Ver Ticket
                </button>
              </div>
            ),
            title: ticket.contact.name,
            draggable: true,
            href: "/tickets/" + ticket.uuid,
            avatarUrl: ticket.contact.profilePicUrl,
          })),
          style: { backgroundColor: tag.color, color: "white" }
        };
      }),
    ];

    setFile({ lanes });
  };

  const handleCardClick = (uuid) => {
    //console.log("Clicked on card with UUID:", uuid);
    history.push('/tickets/' + uuid);
  };

  useEffect(() => {
    popularCards(jsonString);
  }, [tags, tickets, reloadData]);

  /* schaaf
  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {

      await api.delete(`/ticket-tags/${targetLaneId}`);
      //toast.success('Ticket Tag Removido!');
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);
      //toast.success('Ticket Tag Adicionado com Sucesso!');

    } catch (err) {
      console.log(err);
    }
  };
  */

  const  handleDragEnd = async(cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
    if (sourceLaneId === targetLaneId) {return true};
    
    try {
    /*  
      const promiseList = [];
      //kanban INBOX, não existe, não se deve adicionar
      if(targetLaneId !== 'lane0') {promiseList.push(api.put(`/ticket-tags/${cardId}/${targetLaneId}`))};
      if(sourceLaneId !== 'lane0') {promiseList.push(api.delete(`/ticket-tags/${cardId}/${sourceLaneId}`))};
      if(targetLaneId == 'lane0') {promiseList.push(fetchTickets(jsonString))};

      //toast.success('Ticket Tag Adicionado com Sucesso!');
      //toast.success('Ticket Tag Removido!');
      
      await Promise.all(promiseList);
      //toast.success('Ticket Tag Adicionado com Sucesso!');
      //toast.success('Ticket Tag Removido!');
      
      return true; //targetLaneId !== 'lane0';
    */
      //kanban INBOX, não existe, não se deve adicionar
      if(targetLaneId !== 'lane0') {await api.put(`/ticket-tags/${cardId}/${targetLaneId}`)};
      if(sourceLaneId !== 'lane0') {await api.delete(`/ticket-tags/${cardId}/${sourceLaneId}`)};
      if(targetLaneId == 'lane0') {await fetchTickets(jsonString)};

      return true;

    } catch (err) {
      console.log(err);
      //schaaf 20240906 return false não está funcionando
      fetchTags();
      return false;
    }
  }

  const handleOnCardDelete = async(cardId, laneId) => {
    try {
      await api.delete(`/ticket-tags/${cardId}/${laneId}`);
      //toast.success('Ticket Tag Removido!');
    } catch (err) {
      console.log(err);
    }
  }
  const components = {
    Card: KiteCard
  };


  //onCardMoveAcrossLanes={handleCardMove}
  return (
    <div className={classes.root}>
      <Board
        data={file}
        handleDragEnd={handleDragEnd}
        onCardDelete={handleOnCardDelete}
        style={{ backgroundColor: 'rgba(252, 252, 252, 0.03)' }}
        components={components}
      />
    </div>
  );
};


export default Kanban;
