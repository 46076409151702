import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ColorPicker from "../ColorPicker";
import {
  FormControl,
  //Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
} from "@mui/material";
import { Colorize } from "@mui/icons-material";
//import { QueueOptions } from "../QueueOptions";
import SchedulesForm from "../SchedulesForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 256,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const QueueSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
  greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    color: "",
    greetingMessage: "",
    openAiInstructions: "",
    openAiTemperature: null,
    openAiTop_p: null,
    openAiMax_prompt_tokens: 16000,
    openAiMax_completion_tokens: 16000,
    openAiTruncation_strategy_type: 'auto',
    openAiTruncation_strategy_last_messages: 20,
    openAiParallel_tool_calls: true,
    outOfHoursMessage: "",
    orderQueue: "",
    integrationId: "",
    promptId: "",
    filesId: "",
    integrationParmJson: "{}",
    openAiIntention: "",
  };

  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [queue, setQueue] = useState(initialState);
  const [tab, setTab] = useState(0);
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);
  const greetingRef = useRef();
  // const [integrations, setIntegrations] = useState([]);

  const [schedules, setSchedules] = useState([
    { weekday: "Segunda-feira", weekdayEn: "monday", startTime: "08:00", endTime: "18:00", },
    { weekday: "Terça-feira", weekdayEn: "tuesday", startTime: "08:00", endTime: "18:00", },
    { weekday: "Quarta-feira", weekdayEn: "wednesday", startTime: "08:00", endTime: "18:00", },
    { weekday: "Quinta-feira", weekdayEn: "thursday", startTime: "08:00", endTime: "18:00", },
    { weekday: "Sexta-feira", weekdayEn: "friday", startTime: "08:00", endTime: "18:00", },
    { weekday: "Sábado", weekdayEn: "saturday", startTime: "08:00", endTime: "12:00", },
    { weekday: "Domingo", weekdayEn: "sunday", startTime: "00:00", endTime: "00:00", },
  ]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedOpenAiTemperature, setSelectedOpenAiTemperature] = useState(1.0);
  const [selectedOpenAiTop_p, setSelectedOpenAiTop_p] = useState(1.0);
  const [selectedOpenAiTruncation_strategy_type, setSelectedOpenAiTruncation_strategy_type] = useState('');
  const [selectedOpenAiParallel_tool_calls, setSelectedOpenAiParallel_tool_calls] = useState(true);
  
  
  const [prompts, setPrompts] = useState([]);
  const [files, setFiles] = useState([]);
 
  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/prompt");
        setPrompts(data.prompts);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/files");
        setFiles(data.files);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  useEffect(() => {
    api.get(`/settings`).then(({ data }) => {
      if (Array.isArray(data)) {
        const scheduleType = data.find((d) => d.key === "scheduleType");
        if (scheduleType) {
          setSchedulesEnabled(scheduleType.value === "queue");
        }
      }
    });
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { data } = await api.get("/queueIntegration");

  //       setIntegrations(data.queueIntegrations);
  //     } catch (err) {
  //       toastError(err);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setQueue((prevState) => {
          return { ...prevState, ...data };
        });
        data.promptId ? setSelectedPrompt(data.promptId) : setSelectedPrompt(null);
        data.filesId ? setSelectedFile(data.filesId) : setSelectedFile(null);
        data.openAiTemperature ? setSelectedOpenAiTemperature(data.openAiTemperature) : setSelectedOpenAiTemperature(1.0);
        data.openAiTop_p ? setSelectedOpenAiTop_p(data.openAiTop_p) : setSelectedOpenAiTop_p(1.0);
        data.openAiTruncation_strategy_type ? setSelectedOpenAiTruncation_strategy_type(data.openAiTruncation_strategy_type) : setSelectedOpenAiTruncation_strategy_type('auto');
        data.openAiParallel_tool_calls ? setSelectedOpenAiParallel_tool_calls(data.openAiParallel_tool_calls) : setSelectedOpenAiParallel_tool_calls(true);
        
        setSchedules(data.schedules);
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      //setSelectedOpenAiTemperature(1.0);
      setQueue({
        name: "",
        color: "",
        greetingMessage: "",
        openAiInstructions: "",
        openAiTemperature: null,
        openAiTop_p: null,
        openAiMax_prompt_tokens: 16000,
        openAiMax_completion_tokens: 16000,
        openAiTruncation_strategy_type: 'auto',
        openAiTruncation_strategy_last_messages: 20,
        openAiParallel_tool_calls: true,
        outOfHoursMessage: "",
        orderQueue: "",
        integrationId: "",
        integrationParmJson: "{}",
        openAiIntention: ""
      });
    };
  }, [queueId, open]);

  const handleClose = () => {
    onClose();
    setQueue(initialState);
  };

  const handleSaveQueue = async (values) => {
    try {
      if (queueId) {
        await api.put(`/queue/${queueId}`, {
          ...values,
          schedules,
          promptId: selectedPrompt ? selectedPrompt : null,
          filesId: selectedFile ? selectedFile : null,
          openAiTemperature: selectedOpenAiTemperature ? selectedOpenAiTemperature : 1.0,
          openAiTop_p: selectedOpenAiTop_p ? selectedOpenAiTop_p : 1.0,
          openAiTruncation_strategy_type: selectedOpenAiTruncation_strategy_type ? selectedOpenAiTruncation_strategy_type : 'auto', 
          openAiParallel_tool_calls: selectedOpenAiParallel_tool_calls ? selectedOpenAiParallel_tool_calls : true 
        });
      } else {
        await api.post("/queue", {
          ...values,
          schedules,
          promptId: selectedPrompt ? selectedPrompt : null,
          filesId: selectedFile ? selectedFile : null,
          openAiTemperature: selectedOpenAiTemperature ? selectedOpenAiTemperature : 1.0,
          openAiTop_p: selectedOpenAiTop_p ? selectedOpenAiTop_p : 1.0,
          openAiTruncation_strategy_type: selectedOpenAiTruncation_strategy_type ? selectedOpenAiTruncation_strategy_type : 'auto', 
          openAiParallel_tool_calls: selectedOpenAiParallel_tool_calls ? selectedOpenAiParallel_tool_calls : true 
        });
      }
      toast.success("Queue saved successfully");
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleSaveSchedules = async (values) => {
    toast.success("Clique em salvar para registar as alterações");
    setSchedules(values);
    setTab(0);
  };

  const handleChangePrompt = (e) => {
    setSelectedPrompt(e.target.value);
  };

  const handleChangeFile = (e) => {
    setSelectedFile(e.target.value);
  }

  const handleOpenAiTemperature = (e) => {
    setSelectedOpenAiTemperature(e.target.value);
  }

  const handleOpenAiTop_p = (e) => {
    setSelectedOpenAiTop_p(e.target.value);
  }
  
  const handleOpenAiTruncation_strategy_type = (e) => {
    setSelectedOpenAiTruncation_strategy_type(e.target.value);
  }

  const handleOpenAiParallel_tool_calls = (e) => {
    setSelectedOpenAiParallel_tool_calls(e.target.value);
  }
  

  return (
    <div className={classes.root}>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle>
          {queueId
            ? `${i18n.t("queueModal.title.edit")}`
            : `${i18n.t("queueModal.title.add")}`}
        </DialogTitle>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, v) => setTab(v)}
          aria-label="disabled tabs example"
        >
          <Tab label="Dados do Assistente" />
          {schedulesEnabled && <Tab label="Horários de Atendimento" />}
        </Tabs>
        {tab === 0 && (
          <Paper>
            <Formik
              initialValues={queue}
              enableReinitialize={true}
              validationSchema={QueueSchema}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSaveQueue(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <Form>
                  <DialogContent dividers>
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.name")}
                      autoFocus
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.color")}
                      name="color"
                      id="color"
                      onFocus={() => {
                        setColorPickerModalOpen(true);
                        greetingRef.current.focus();
                      }}
                      error={touched.color && Boolean(errors.color)}
                      helperText={touched.color && errors.color}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{ backgroundColor: values.color }}
                              className={classes.colorAdorment}
                            ></div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <IconButton
                            size="small"
                            color="default"
                            onClick={() => setColorPickerModalOpen(true)}
                          >
                            <Colorize />
                          </IconButton>
                        ),
                      }}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                    <ColorPicker
                      open={colorPickerModalOpen}
                      handleClose={() => setColorPickerModalOpen(false)}
                      onChange={(color) => {
                        values.color = color;
                        setQueue(() => {
                          return { ...values, color };
                        });
                      }}
                    />
                    <Field
                      as={TextField}
                      label={i18n.t("queueModal.form.orderQueue")}
                      name="orderQueue"
                      type="orderQueue"
                      error={touched.orderQueue && Boolean(errors.orderQueue)}
                      helperText={touched.orderQueue && errors.orderQueue}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField1}
                    />
                    <div>
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        className={classes.formControl}
                        fullWidth
                      >
                        {/* A integracao sera com n8n via a conexão WhatsApp 
                        <InputLabel id="integrationId-selection-label">
                          {i18n.t("queueModal.form.integrationId")}
                        </InputLabel>
                        <Field
                          as={Select}
                          label={i18n.t("queueModal.form.integrationId")}
                          name="integrationId"
                          id="integrationId"
                          placeholder={i18n.t("queueModal.form.integrationId")}
                          labelId="integrationId-selection-label"
                          value={values.integrationId || ""}
                        >
                          <MenuItem value={""} >{"Nenhum"}</MenuItem>
                          {integrations.map((integration) => (
                            <MenuItem key={integration.id} value={integration.id}>
                              {integration.name}
                            </MenuItem>
                          ))}
                        </Field>
                      */}

                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.openAiIntention")}
                          multiline
                          rows={5}
                          autoFocus
                          fullWidth
                          name="openAiIntention"
                          error={touched.openAiIntention && Boolean(errors.openAiIntention)}
                          helperText={touched.openAiIntention && errors.openAiIntention}
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />
                      </FormControl>

                      <FormControl
                        variant="outlined"
                        margin="dense"
                        className={classes.formControl}
                        fullWidth
                      >

                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.integrationParmJson")}
                          multiline
                          rows={5}
                          autoFocus
                          fullWidth
                          name="integrationParmJson"
                          error={touched.integrationParmJson && Boolean(errors.integrationParmJson)}
                          helperText={touched.integrationParmJson && errors.integrationParmJson}
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                        />

                      </FormControl>

                      {/* <FormControl
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      >
                        <InputLabel>
                          {i18n.t("whatsappModal.form.prompt")}
                        </InputLabel>
                        <Select
                          labelId="dialog-select-prompt-label"
                          id="dialog-select-prompt"
                          name="promptId"
                          value={selectedPrompt || ""}
                          onChange={handleChangePrompt}
                          label={i18n.t("whatsappModal.form.prompt")}
                          fullWidth
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {prompts.map((prompt) => (
                            <MenuItem
                              key={prompt.id}
                              value={prompt.id}
                            >
                              {prompt.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}

                      <FormControl
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      >
                        <InputLabel>
                          Lista de Arquivos IA
                        </InputLabel>
                        <Select
                          labelId="dialog-select-file-label"
                          id="dialog-select-file"
                          name="fileId"
                          value={selectedFile || ""}
                          onChange={handleChangeFile}
                          label="Sem Lista de Arquivos para IA"
                          fullWidth
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem value="">
                            <em>Sem Lista de Arquivos para IA</em>
                          </MenuItem>
                          {files.map((file) => (
                            <MenuItem
                              key={file.id}
                              value={file.id}
                            >
                              {file.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    {/*20250119 schaaf campos que não estão sendo utilizados
                    <div style={{ marginTop: 5 }}>
                      <Field
                        as={TextField}
                        label={i18n.t("queueModal.form.greetingMessage")}
                        type="greetingMessage"
                        multiline
                        inputRef={greetingRef}
                        rows={5}
                        fullWidth
                        name="greetingMessage"
                        error={
                          touched.greetingMessage &&
                          Boolean(errors.greetingMessage)
                        }
                        helperText={
                          touched.greetingMessage && errors.greetingMessage
                        }
                        variant="outlined"
                        margin="dense"
                      />
                      {schedulesEnabled && (
                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.outOfHoursMessage")}
                          type="outOfHoursMessage"
                          multiline
                          inputRef={greetingRef}
                          rows={5}
                          fullWidth
                          name="outOfHoursMessage"
                          error={
                            touched.outOfHoursMessage &&
                            Boolean(errors.outOfHoursMessage)
                          }
                          helperText={
                            touched.outOfHoursMessage && errors.outOfHoursMessage
                          }
                          variant="outlined"
                          margin="dense"
                        />
                      )}
                    </div>
                    <QueueOptions queueId={queueId} />
                     */}
                    <div style={{ marginTop: 5 }}>
                      <Field
                        as={TextField}
                        label={i18n.t("queueModal.form.openAiInstructions")}
                        type="openAiInstructions"
                        multiline
                        inputRef={greetingRef}
                        rows={5}
                        fullWidth
                        name="openAiInstructions"
                        error={
                          touched.openAiInstructions &&
                          Boolean(errors.openAiInstructions)
                        }
                        helperText={
                          touched.openAiInstructions && errors.openAiInstructions
                        }
                        variant="outlined"
                        margin="dense"
                      />
                      <div className={classes.multFieldLine}>
                        <FormControl className={classes.formControl} margin="dense" variant="outlined">
                          <InputLabel>{i18n.t("queueModal.form.openAiTemperature")}</InputLabel>
                          <Select
                            id="type-select"
                            labelWidth={93}
                            name="openAiTemperature"
                            value={selectedOpenAiTemperature}
                            onChange={handleOpenAiTemperature}
                            multiple={false}
                          >
                            {/* <MenuItem key={"1"} value={"1"}>
                              1
                            </MenuItem> */}
                            {Array.from({ length: 21 }, (_, i) => (
                              <MenuItem key={i} value={(i / 10).toFixed(1)}>
                                {(i / 10).toFixed(1)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl className={classes.formControl} margin="dense" variant="outlined">
                          <InputLabel>{i18n.t("queueModal.form.openAiTop_p")}</InputLabel>
                          <Select
                            id="type-select"
                            labelWidth={42}
                            name="openAiTop_p"
                            value={selectedOpenAiTop_p}
                            onChange={handleOpenAiTop_p}
                            multiple={false}
                          >
                            {/* <MenuItem key={"1"} value={"1"}>
                              1
                            </MenuItem> */}
                            {Array.from({ length: 11 }, (_, i) => (
                              <MenuItem key={i} value={(i / 10).toFixed(1)}>
                                {(i / 10).toFixed(1)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.openAiMax_prompt_tokens")}
                          name="openAiMax_prompt_tokens"
                          error={touched.openAiMax_prompt_tokens && Boolean(errors.openAiMax_prompt_tokens)}
                          helperText={touched.openAiMax_prompt_tokens && errors.openAiMax_prompt_tokens}
                          variant="outlined"
                          margin="dense"
                          className={classes.formControl2}
                        />
                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.openAiMax_completion_tokens")}
                          name="openAiMax_completion_tokens"
                          error={touched.openAiMax_completion_tokens && Boolean(errors.openAiMax_completion_tokens)}
                          helperText={touched.openAiMax_completion_tokens && errors.openAiMax_completion_tokens}
                          variant="outlined"
                          margin="dense"
                          className={classes.formControl2}
                        />

                      </div>
                      <div className={classes.multFieldLine}>
                        <FormControl className={classes.formControl2} margin="dense" variant="outlined">
                          <InputLabel>{i18n.t("queueModal.form.openAiTruncation_strategy_type")}</InputLabel>
                          <Select
                            id="type-select"
                            labelWidth={234}
                            name="openAiTruncation_strategy_type"
                            value={selectedOpenAiTruncation_strategy_type}
                            onChange={handleOpenAiTruncation_strategy_type}
                            multiple={false}
                          >
                          <MenuItem key={"auto"} value={"auto"}>
                              Automático
                          </MenuItem>
                          <MenuItem key={"last_messages"} value={"last_messages"}>
                              Últimas Mensagens
                          </MenuItem>

                          </Select>
                        </FormControl>
                        <Field
                          as={TextField}
                          label={i18n.t("queueModal.form.openAiTruncation_strategy_last_messages")}
                          name="openAiTruncation_strategy_last_messages"
                          error={touched.openAiTruncation_strategy_last_messages && Boolean(errors.openAiTruncation_strategy_last_messages)}
                          helperText={touched.openAiTruncation_strategy_last_messages && errors.openAiTruncation_strategy_last_messages}
                          variant="outlined"
                          margin="dense"
                          className={classes.formControl2}
                        />
                        <FormControl className={classes.formControl2} margin="dense" variant="outlined">
                          <InputLabel>{i18n.t("queueModal.form.openAiParallel_tool_calls")}</InputLabel>
                          <Select
                            id="type-select"
                            labelWidth={221}
                            name="openAiParallel_tool_calls"
                            value={selectedOpenAiParallel_tool_calls}
                            onChange={handleOpenAiParallel_tool_calls}
                            multiple={false}
                          >
                          <MenuItem key={"Sim"} value={true}>
                              Sim
                          </MenuItem>
                          <MenuItem key={"Não"} value={false}>
                              Não
                          </MenuItem>

                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      {i18n.t("queueModal.buttons.cancel")}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      {queueId
                        ? `${i18n.t("queueModal.buttons.okEdit")}`
                        : `${i18n.t("queueModal.buttons.okAdd")}`}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Paper>
        )}
        {tab === 1 && (
          <Paper style={{ padding: 20 }}>
            <SchedulesForm
              loading={false}
              onSubmit={handleSaveSchedules}
              initialValues={schedules}
              labelSaveButton="Adicionar"
            />
          </Paper>
        )}
      </Dialog>
    </div>
  );
};

export default QueueModal;
