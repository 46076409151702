import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const TagSelect = ({ selectedTagIds, tagExclude, onChange, multiple = true, title = i18n.t("tagSelect.inputLabel") }) => {
	const classes = useStyles();
	const [tags, setTags] = useState([]);

	useEffect(() => {

		fetchTags(tagExclude);

	}, []);

	const fetchTags = async (tagExclude) => {
		try {
			const { data } = await api.get("/tags/list");
			const dataFiltered = data.filter(tag => {
				return tag.id !== tagExclude;
			});
			setTags(dataFiltered);
		} catch (err) {
			toastError(err);
		}
	}

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div >
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel shrink={selectedTagIds ? true : false} >{title}</InputLabel>
				<Select
					label={title}
					multiple={multiple}
					labelWidth={60}
					value={selectedTagIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}

					renderValue={selected => {
						return (
							<div className={classes.chips}>
								{selected?.length > 0 && multiple ? (
									selected.map(id => {
										const tag = tags.find(q => q.id === id);
										return tag ? (
											<Chip
												key={id}
												style={{ backgroundColor: tag.color }}
												variant="outlined"
												label={tag.name}
												className={classes.chip}
											/>
										) : null;
									})

								) :
									(
										<Chip
											key={selected}
											variant="outlined"
											style={{ backgroundColor: tags.find(q => q.id === selected)?.color }}
											label={tags.find(q => q.id === selected)?.name}
											className={classes.chip}
										/>
									)
								}

							</div>
						)
					}}
				>
					{!multiple && <MenuItem value={null}>Nenhum</MenuItem>}
					{tags.map(tag => (
						<MenuItem key={tag.id} value={tag.id}>
							{tag.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default TagSelect;
