//https://stackoverflow.com/questions/69602508/react-trello-card-properties
//https://github.com/rcdexta/react-trello/issues/331
import Avatar from "@material-ui/core/Avatar";

import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {
  MovableCardWrapper,
  CardHeader,
  CardRightContent,
  CardTitle,
  Detail,
  Footer
} from 'react-trello/dist/styles/Base'
import InlineInput from 'react-trello/dist/widgets/InlineInput'
import Tag from 'react-trello/dist/components/Card/Tag'
import DeleteButton from 'react-trello/dist/widgets/DeleteButton'

class KiteCard extends Component {
  onDelete = e => {
    this.props.onDelete()
    e.stopPropagation()
  }

  render()  {
    const {
      showDeleteButton,
      style,
      tagStyle,
      onClick,
      onDelete,
      onChange,
      className,
      id,
      title,
      label,
      description,
      tags,
      cardDraggable,
      editable,
      t,
      avatarUrl
    } = this.props

    const updateCard = (card) => {
      onChange({...card, id})
    }

    return (
      <MovableCardWrapper
        data-id={id}
        onClick={onClick}
        style={style}
        className={className}
      >
        <CardHeader>
          <CardTitle draggable={cardDraggable}>
            <Avatar src={avatarUrl} style={{ width: '24px', height: '24px', display: 'inline-flex' }}/>
            <div style={{ display: 'inline-block' ,position: 'absolute', top: '15px', 'margin-left': '5px'
             }}>
              {editable ? <InlineInput value={title} border placeholder={t('placeholder.title')} resize='vertical' onSave={(value) => updateCard({title: value})} /> : title}
            </div>
          </CardTitle>
          
          {/*
          <CardRightContent>
            {editable ? <InlineInput value={label} border placeholder={t('placeholder.label')} resize='vertical' onSave={(value) => updateCard({label: value})} /> : label}
          </CardRightContent>
          */}
          {showDeleteButton && <DeleteButton onClick={this.onDelete} />}
        </CardHeader>
        <Detail>
          {editable ? <InlineInput value={description} border placeholder={t('placeholder.description')} resize='vertical' onSave={(value) => updateCard({description: value})} /> : description}
        </Detail>
        {tags && tags.length> 0 && (
          <Footer>
            {tags.map(tag => (
              <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
            ))}
          </Footer>
        )}
      </MovableCardWrapper>
      )
  }
}

KiteCard.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.object || PropTypes.string,
  tags: PropTypes.array,
}

KiteCard.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  tagStyle: {},
  title: 'no title',
  description: '',
  label: '',
  tags: [],
  className: ''
}

export default KiteCard