import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Colorize } from "@material-ui/icons";
import { ColorBox } from 'material-ui-color';
import ColorPicker from "../ColorPicker";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { IconButton, InputAdornment } from "@material-ui/core";
import { FormControlLabel, Switch } from '@material-ui/core';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import TagSelect from "../TagSelect";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const TagSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, "Mensagem muito curta")
		.required("Obrigatório")
});

const TagModal = ({ open, onClose, tagId, reload }) => {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [selectedTagIds, setSelectedTagIds] = useState([]);

	const initialState = {
		name: "",
		color: "",
		kanban: 0,
		mensagem: "",
		dias: 0,
		horas: 0,
		minutos: 0,
		repeticoes: 1,
		openAiIntention: "",
		openAiResponse: "",
	};

	const [tag, setTag] = useState(initialState);
	const [ kanban, setKanban] = useState(0);

	useEffect(() => {
		try {
			(async () => {
				if (!tagId) return;

				const { data } = await api.get(`/tags/${tagId}`);
				setKanban(data.kanban);
				let selectedTagIds = [];
				data.tagTags.forEach(tagTag => {
					selectedTagIds.push(tagTag.tagTagsId)
				});
				setSelectedTagIds(selectedTagIds);
				setTag(prevState => {
					return { ...prevState, ...data };
				});
			})()
		} catch (err) {
			toastError(err);
		}
	}, [tagId, open]);

	const handleClose = () => {
		setTag(initialState);
		setColorPickerModalOpen(false);
		onClose();
	};

	const handleKanbanChange = (e) => {
		setKanban( e.target.checked ? 1 : 0);
	};

	const handleSaveTag = async values => {
		const tagData = { ...values, userId: user.id, kanban , tagTagsIds: selectedTagIds};
		try {
			if (tagId) {
				await api.put(`/tags/${tagId}`, tagData);
			} else {
				await api.post("/tags", tagData);
			}
			toast.success(i18n.t("tagModal.success"));
			if (typeof reload == 'function') {
				reload();
			}
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="md"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{(tagId ? `${i18n.t("tagModal.title.edit")}` : `${i18n.t("tagModal.title.add")}`)}
				</DialogTitle>
				<Formik
					initialValues={tag}
					enableReinitialize={true}
					validationSchema={TagSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveTag(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<TextField
										//as={TextField}
										label={i18n.t("tagModal.form.name")}
										name="name"
										value={tag.name || ''}
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										onChange={(e) => setTag(prev => ({ ...prev, name: e.target.value }))}
										fullWidth
									/>
								</div>
								<br />
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										fullWidth
										label={i18n.t("tagModal.form.color")}
										name="color"
										value={tag.color || ''}
										id="color"
										error={touched.color && Boolean(errors.color)}
										helperText={touched.color && errors.color}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<div
														style={{ backgroundColor: values.color }}
														className={classes.colorAdorment}
													></div>
												</InputAdornment>
											),
											endAdornment: (
												<IconButton
													size="small"
													color="default"
													onClick={() => setColorPickerModalOpen(true)}
												>
													<Colorize />
												</IconButton>
											),
										}}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<br />

								{(user.profile === "admin" || user.profile === "super") && (
                                <>
								<div className={classes.multFieldLine}>
        							<FormControlLabel
          								control={
            								<Checkbox
             									checked={kanban === 1}
             									onChange={handleKanbanChange}
              									value={kanban || false}
              									color="primary"
            								/>
          								}
          								label="Kanban"
          								labelPlacement="start"
        							/>
      							</div>
      							<br />
                                </>
								)}

<								TagSelect
									selectedTagIds={selectedTagIds}
									tagExclude={tagId}
									onChange={values => setSelectedTagIds(values)}
									/>
								<br />

								{/* MENSAGEM */}
								<div className={classes.multFieldLine} >
									<TextField
										//as={TextField}
										label="Mensagem"
										name="mensagem"
										value={tag.mensagem || ''}
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										onChange={(e) => setTag(prev => ({ ...prev, mensagem: e.target.value }))}
										fullWidth
										multiline
										minRows={2}										
										maxRows={4}										 
									/>
								</div>

								<br />


								<div>
								{/* DIAS */}
								<TextField
									id="outlined-number"
									label="Dias"
									type="number"
									slotprops={{
										inputLabel: {
											shrink: true,
										},
									}}
									InputProps={{
										inputProps: { min: 0 }
									}}
									style={{ "width": "80px" }}
									onChange={(e) => setTag(prev => ({ ...prev, dias: parseInt(e.target.value) }))}
									value={tag.dias || 0}
								/>

								{/* HORAAS */}								
								<FormControl variant="standard" style={{ m: 1
										, minWidth: 80
										, marginLeft: '18px'
									 }}>
									<InputLabel id="tag-horas-label">Horas</InputLabel>
									<Select
									labelId="tag-horas-label"
									id="tag-horas"
									label="Horas"
									onChange={(e) => setTag(prev => ({ ...prev, horas: parseInt(e.target.value) }))}
									value={tag.horas || 0}
									>
									<MenuItem value={0}>00</MenuItem>
									<MenuItem value={1}>01</MenuItem>
									<MenuItem value={2}>02</MenuItem>
									<MenuItem value={3}>03</MenuItem>
									<MenuItem value={4}>04</MenuItem>
									<MenuItem value={5}>05</MenuItem>
									<MenuItem value={6}>06</MenuItem>
									<MenuItem value={7}>07</MenuItem>
									<MenuItem value={8}>08</MenuItem>
									<MenuItem value={9}>09</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={11}>11</MenuItem>
									<MenuItem value={12}>12</MenuItem>
									<MenuItem value={13}>13</MenuItem>
									<MenuItem value={14}>14</MenuItem>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={16}>16</MenuItem>
									<MenuItem value={17}>17</MenuItem>
									<MenuItem value={18}>18</MenuItem>
									<MenuItem value={19}>19</MenuItem>
									<MenuItem value={20}>20</MenuItem>
									<MenuItem value={21}>21</MenuItem>
									<MenuItem value={22}>22</MenuItem>
									<MenuItem value={23}>23</MenuItem>

									</Select>
								</FormControl>

								{/* MINUTOS */}
								<FormControl variant="standard" style={{ m: 1
										, minWidth: 80
										, marginLeft: '18px'
									 }}>
									<InputLabel id="tag-minutos-label">Minutos</InputLabel>
									<Select
									labelId="tag-minutos-label"
									id="tag-minutos"
									label="Minutos"
									onChange={(e) => setTag(prev => ({ ...prev, minutos: parseInt(e.target.value) }))}
									value={tag.minutos || 0}
									>
									<MenuItem value={0}>00</MenuItem>
									<MenuItem value={5}>05</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={20}>20</MenuItem>
									<MenuItem value={25}>25</MenuItem>
									<MenuItem value={30}>30</MenuItem>
									<MenuItem value={35}>35</MenuItem>
									<MenuItem value={40}>40</MenuItem>
									<MenuItem value={45}>45</MenuItem>
									<MenuItem value={50}>50</MenuItem>
									<MenuItem value={55}>55</MenuItem>

									</Select>
								</FormControl>

								{/* REPETICOES */}
								<FormControl variant="standard" style={{ m: 1
										, minWidth: 80
										, marginLeft: '18px'
									 }}>
									<InputLabel id="tag-repeticoes-label">Repetições</InputLabel>
									<Select
									labelId="tag-repeticoes-label"
									id="tag-repeticoes"
									label="Repetições"
									onChange={(e) => setTag(prev => ({ ...prev, repeticoes: parseInt(e.target.value) }))}
									value={tag.repeticoes || 1}
									>
									<MenuItem value={1}>1</MenuItem>
									<MenuItem value={2}>2</MenuItem>
									<MenuItem value={3}>3</MenuItem>
									<MenuItem value={4}>4</MenuItem>
									<MenuItem value={5}>5</MenuItem>
									</Select>
								</FormControl>
								</div>

								<br />

								{/* openAiIntention */}
								<div className={classes.multFieldLine} >
									<TextField
										// as={TextField}
										label="IA Intenção"
										name="openAiIntention"
										value={tag.openAiIntention || ''}
										error={touched.openAiIntention && Boolean(errors.openAiIntention)}
										helperText={touched.openAiIntention && errors.openAiIntention}
										variant="outlined"
										margin="dense"
										onChange={(e) => setTag(prev => ({ ...prev, openAiIntention: e.target.value }))}
										fullWidth
										multiline
										minRows={2}	
										maxRows={5}										 
									/>
								</div>

								{/* openAiResponse */}
								<div className={classes.multFieldLine} >
									<TextField
										//as={TextField}
										label="IA Intenção Resposta"
										name="openAiResponse"
										value={tag.openAiResponse || ''}
										error={touched.openAiResponse && Boolean(errors.openAiResponse)}
										helperText={touched.openAiResponse && errors.openAiResponse}
										variant="outlined"
										margin="dense"
										onChange={(e) => setTag(prev => ({ ...prev, openAiResponse: e.target.value }))}
										fullWidth
										multiline
										minRows={2}											
										maxRows={4}										 
									/>
								</div>

								{colorPickerModalOpen && (
									<div>
										<ColorPicker
											open={colorPickerModalOpen}
											handleClose={() => setColorPickerModalOpen(false)}
											// disableAlpha={true}
											// hslGradient={false}
											// style={{ margin: '20px auto 0' }}
											value={tag.color || ''}
											onChange={(color) => {
												values.color = color;
												//setTag(prev => ({ ...prev, color: `#${val.hex}` }));
												setTag(() => {
													return { ...values, color };
												});												
											}}
										/>
									</div>
								)}
							</DialogContent>

							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("tagModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{tagId
										? `${i18n.t("tagModal.buttons.okEdit")}`
										: `${i18n.t("tagModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default TagModal;
