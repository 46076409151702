
import React, { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { ptBR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import ColorModeContext from "./layout/themeContext";

import Routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
    const [locale, setLocale] = useState();

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const preferredTheme = window.localStorage.getItem("preferredTheme");
    const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light");

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        []
    );

    const theme = createTheme(
        {
            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: '8px',
                    height: '8px',
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: "#3C64F1", // Azul Principal
                },
            },
            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? "#F7F8FC" : "#333333", // Cinza Claro no tema claro
                },
            },
            palette: {
                type: mode,
                primary: { main: mode === "light" ? "#3C64F1" : "#FFFFFF" }, // Azul Principal no tema claro
                textPrimary: mode === "light" ? "#4A4A4A" : "#FFFFFF", // Cinza Escuro para texto
                borderPrimary: mode === "light" ? "#E3E4EF" : "#FFFFFF", // Cinza Médio para bordas
                dark: { main: mode === "light" ? "#4A4A4A" : "#F3F3F3" }, // Cinza Escuro para modo claro
                light: { main: mode === "light" ? "#F7F8FC" : "#333333" }, // Cinza Claro para fundo no modo claro
                tabHeaderBackground: mode === "light" ? "#E3E4EF" : "#666", // Cinza Médio para cabeçalho
                optionsBackground: mode === "light" ? "#C2D1FF" : "#333", // Azul Claro para fundo de opções
				options: mode === "light" ? "#C2D1FF" : "#666", // Azul Claro para opções
				fontecor: mode === "light" ? "#FF7A45" : "#fff", // Laranja para alertas
                fancyBackground: mode === "light" ? "#F7F8FC" : "#333", // Cinza Claro para fundo
				bordabox: mode === "light" ? "#E3E4EF" : "#333", // Cinza Médio para bordas de caixas
				newmessagebox: mode === "light" ? "#E3E4EF" : "#333", // Cinza Médio para novas mensagens
				inputdigita: mode === "light" ? "#FFFFFF" : "#666", // Branco para inputs
				contactdrawer: mode === "light" ? "#FFFFFF" : "#666", // Branco para gaveta de contatos
				announcements: mode === "light" ? "#F7F8FC" : "#333", // Cinza Claro para anúncios
				login: mode === "light" ? "#FFFFFF" : "#1C1C1C", // Branco para login
				announcementspopover: mode === "light" ? "#FFFFFF" : "#666", // Branco para popover de anúncios
				chatlist: mode === "light" ? "#E3E4EF" : "#666", // Cinza Médio para lista de chat
				boxlist: mode === "light" ? "#E3E4EF" : "#666", // Cinza Médio para caixas de lista
				boxchatlist: mode === "light" ? "#E3E4EF" : "#333", // Cinza Médio para caixa de chat na lista
                total: mode === "light" ? "#FFFFFF" : "#222", // Branco para total
                messageIcons: mode === "light" ? "#4A4A4A" : "#F3F3F3", // Cinza Escuro para ícones de mensagens
                inputBackground: mode === "light" ? "#FFFFFF" : "#333", // Branco para fundo de input
                barraSuperior: mode === "light" ? "#3C64F1" : "#666", // Azul Principal para a barra superior
				boxticket: mode === "light" ? "#E3E4EF" : "#666", // Cinza Médio para caixa de tickets
				campaigntab: mode === "light" ? "#E3E4EF" : "#666", // Cinza Médio para aba de campanha
				mediainput: mode === "light" ? "#E3E4EF" : "#1c1c1c", // Cinza Médio para input de mídia
            },
            mode,
        },
        locale
    );

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale =
            i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode);
    }, [mode]);

    return (
        <ColorModeContext.Provider value={{ colorMode }}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <Routes />
                </QueryClientProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default App;


/*
import React, { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { ptBR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import ColorModeContext from "./layout/themeContext";

import Routes from "./routes";

const queryClient = new QueryClient();

const App = () => {
    const [locale, setLocale] = useState();

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const preferredTheme = window.localStorage.getItem("preferredTheme");
    const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light");

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        []
    );

    const theme = createTheme(
        {
            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: '8px',
                    height: '8px',
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: "#586CFA",
                },
            },
            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? "#F3F3F3" : "#333333",
                },
            },
            palette: {
                type: mode,
                primary: { main: mode === "light" ? "#682EE4" : "#FFFFFF" },
                textPrimary: mode === "light" ? "#682EE4" : "#FFFFFF",
                borderPrimary: mode === "light" ? "#682EE4" : "#FFFFFF",
                dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
                light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
                tabHeaderBackground: mode === "light" ? "#EEE" : "#666",
                optionsBackground: mode === "light" ? "#fafafa" : "#333",
				options: mode === "light" ? "#fafafa" : "#666",
				fontecor: mode === "light" ? "#128c7e" : "#fff",
                fancyBackground: mode === "light" ? "#fafafa" : "#333",
				bordabox: mode === "light" ? "#eee" : "#333",
				newmessagebox: mode === "light" ? "#eee" : "#333",
				inputdigita: mode === "light" ? "#fff" : "#666",
				contactdrawer: mode === "light" ? "#fff" : "#666",
				announcements: mode === "light" ? "#ededed" : "#333",
				login: mode === "light" ? "#fff" : "#1C1C1C",
				announcementspopover: mode === "light" ? "#fff" : "#666",
				chatlist: mode === "light" ? "#eee" : "#666",
				boxlist: mode === "light" ? "#ededed" : "#666",
				boxchatlist: mode === "light" ? "#ededed" : "#333",
                total: mode === "light" ? "#fff" : "#222",
                messageIcons: mode === "light" ? "grey" : "#F3F3F3",
                inputBackground: mode === "light" ? "#FFFFFF" : "#333",
                barraSuperior: mode === "light" ? "linear-gradient(to right, #682EE4, #682EE4 , #682EE4)" : "#666",
				boxticket: mode === "light" ? "#EEE" : "#666",
				campaigntab: mode === "light" ? "#ededed" : "#666",
				mediainput: mode === "light" ? "#ededed" : "#1c1c1c",
            },
            mode,
        },
        locale
    );

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale =
            i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode);
    }, [mode]);



    return (
        <ColorModeContext.Provider value={{ colorMode }}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <Routes />
                </QueryClientProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default App;
*/